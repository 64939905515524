import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";
import "../styles/globals.scss";
import Head from 'next/head'

const start = () => {
  if (document) {
    document.documentElement.classList.add("normal-scroll");
  }
};
const stop = () => {
  if (document) {
    document.documentElement.classList.remove("normal-scroll");
  }
};

function useNormalScrollRoutes() {
  const router = useRouter();

  useEffect(() => {
    if (router && router.events) {
      router.events.on("routeChangeStart", start);
      router.events.on("routeChangeComplete", stop);
    }
    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", stop);
    };
  }, [router]);
}

function MyApp({ Component, pageProps }: AppProps) {
  useNormalScrollRoutes();
  return (
    <Component {...pageProps} />
)}

export default appWithTranslation(MyApp);
